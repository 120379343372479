<template>
  <div class="course-info-box">
    <module-title text="课程信息" />
    <div class="course-info">
      <div v-if="showFlag === 'ddxq'" class="label-text-item">
        <div class="label-text">订单编号：</div>
        <div class="value-text">{{ detail.orderNo }}</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">课程名称：</div>
        <div class="value-text">{{ detail.courseName }}</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">开课时间：</div>
        <div class="value-text">
          <div v-for="(item, index) in detail.courseSettingList" :key="'kksj' + index">
            {{ getDayWeekMonth(item) }}
          </div>
        </div>
      </div>
      <div class="label-text-item">
        <div class="label-text">开课周期：</div>
        <div class="value-text">{{ detail.courseOpenStart }} ~ {{ detail.courseOpenEnd }}</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">报名时间：</div>
        <div class="value-text">{{ detail.applyDateStart.slice(0, 16) }} ~ {{ detail.applyDateEnd.slice(0, 16) }}</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">老师：</div>
        <div class="value-text">{{ detail.teacher }}</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">开班人数：</div>
        <div v-if="detail.isAstrictPersons === 1" class="value-text">{{ detail.minPerson }} ~ {{ detail.maxPerson }}人</div>
        <div v-else class="value-text">不限人数</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">课程地点：</div>
        <div class="value-text">{{ detail.courseAddr }}</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">课程管理员：</div>
        <div class="value-text">
          {{ detail.courseAdmin || '-' }} <span v-show="detail.courseAdminMobile">(<phone-number-show :phoneNumber="detail.courseAdminMobile" />)</span>
        </div>
      </div>
    </div>
    <module-title text="报名信息" />
    <van-form class="sign-up-form" validate-first @failed="onFailed">
      <van-field
        v-model="form.userName"
        label="报名人"
        :placeholder="showFlag === 'ddxq' ? '' : '请输入报名人'"
        input-align="right"
        :readonly="showFlag === 'ddxq'"
      />
      <van-field
        v-model="form.mobileNum"
        label="联系电话"
        :placeholder="showFlag === 'ddxq' ? '' : '请输入联系电话'"
        input-align="right"
        :readonly="showFlag === 'ddxq'"
      />
      <van-field v-if="showFlag === 'ddxq'" v-model="detail.applyStatusText" label="报名状态" placeholder="" input-align="right" readonly />
      <van-field v-if="showFlag === 'ddxq'" v-model="detail.chargeTypeText" label="收费方式" placeholder="" input-align="right" readonly />
    </van-form>
    <!-- <module-title text="支付方式" />
    <van-radio-group class="zffs-box" v-model="form.payType">
      <van-cell-group>
        <van-cell title="微信" icon="wechat-pay" clickable @click="form.payType = 1">
          <template #right-icon>
            <van-radio :name="1" shape="square" />
          </template>
        </van-cell>
        <van-cell title="支付宝" icon="alipay" clickable @click="form.payType = 2">
          <template #right-icon>
            <van-radio :name="2" shape="square" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group> -->
    <div class="tips-box">
      <p>*温馨提示：</p>
      <p>1、当报名截止但未达到开班人数时，系统将自动退款</p>
      <p>2、仅支持报名结束前取消报名，其他情况不支持，需联系课程管理员手动退款</p>
    </div>
  </div>
</template>

<script>
import phoneNumberShow from '@/components/bussiness/phoneNumberShow.vue';
import moduleTitle from '@/components/bussiness/moduleTitle.vue';
import { dayWeekMonthMap, weeksMap, monthsMap, getDayWeekMonth } from '../map.js';
import Vue from 'vue';
import { Cell, CellGroup, Form, Field } from 'vant';

Vue.use(Form);
Vue.use(Field);
Vue.use(Cell);
Vue.use(CellGroup);

export default {
  name: 'courseInfo',
  components: { phoneNumberShow, moduleTitle },
  props: {
    detail: {
      type: Object,
      default: () => ({
        applyDateStart: '',
        applyDateEnd: '',
        courseSettingList: []
      })
    },
    form: {
      type: Object,
      default: () => ({})
    },
    showFlag: {
      type: String,
      default: 'qrbm'
    }
  },
  data() {
    return {
      dayWeekMonthMap,
      weeksMap,
      monthsMap
    };
  },
  created() {},
  methods: {
    onFailed() {},
    ljbmOnClick() {},
    getDayWeekMonth
  }
};
</script>

<style lang="less" scoped>
.course-info-box {
  box-sizing: border-box;
  padding: 20px 0px;
}
.course-info {
  box-sizing: border-box;
  width: calc(100% - 60px);
  margin: 0px 30px 30px 30px;
  border-radius: 16px;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.2);
  padding: 22px 30px;
  .label-text-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid #eeeeee;
    padding: 10px 0px 16px 0px;
    .label-text {
      flex-shrink: 0;
      width: 162px;
      font-size: 26px;
      line-height: 36px;
      color: #333333;
    }
    .value-text {
      font-size: 26px;
      line-height: 36px;
      color: rgba(0, 0, 0, 0.85);
    }
    &:last-child {
      margin-bottom: 0px;
      border-bottom: none;
    }
  }
}
.sign-up-form {
  box-sizing: border-box;
  display: block;
  width: calc(100% - 60px);
  margin: 0px 30px 30px 30px;
  border-radius: 16px;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.2);
  overflow: hidden;
}
.zffs-box {
  box-sizing: border-box;
  width: calc(100% - 60px);
  margin: 0px 30px 30px 30px;
  border-radius: 16px;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.2);
  overflow: hidden;
  .van-icon-wechat-pay {
    color: #4cc851;
  }
  .van-icon-alipay {
    color: #2076f8;
  }
}
.tips-box {
  box-sizing: border-box;
  font-size: 24px;
  color: #e02020;
  line-height: 34px;
  margin: 0px 34px 30px 34px;
}

.module-title {
  margin: 0px 30px 20px 30px;
}
</style>
